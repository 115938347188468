body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
@font-face {
  font-family: "Forza";
  src: local("Forza"), url("./fonts/Forza-Bold.otf") format("opentype");
}
@font-face {
  font-family: "digital-7Final";
  src: url("./fonts/digital-7Final.ttf") format("opentype");
}
@font-face {
  font-family: "Redig";
  src: local("Redig"), url(./fonts/Redig-Regular.otf) format("opentype");
}

@font-face {
  font-family: "BebasNeue-Regular";
  src: url("./fonts/BebasNeue-Regular.ttf") format("opentype");
}

@font-face {
  font-family: "BelizioBold";
  src: url("./fonts/BelizioBold.ttf") format("opentype");
}
@font-face {
  font-family: "ScoutCond-Bold";
  src: url("./fonts/ScoutCond-Bold.otf") format("opentype");
}
@font-face {
  font-family: "MinionPro-Regular";
  src: url("./fonts/MinionPro-Regular.otf") format("opentype");
}
@font-face {
  font-family: "Metronic_Slab_Pro_Black";
  src: url("./fonts/Metronic_Slab_Pro_Black.otf") format("opentype");
}
@font-face {
  font-family: "CheddarGothicRough-Regular";
  src: url("./fonts/CheddarGothicRough-Regular.otf") format("opentype");
}
